var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-main",
        [
          _c("head-layout", {
            attrs: {
              "head-btn-options": _vm.headBtnOptions,
              "head-title": "境内项目人员信息编辑",
            },
            on: {
              "head-cancel": _vm.headCancel,
              "head-save": _vm.headSave,
              "head-saveBack": function ($event) {
                return _vm.headSave(true)
              },
              "head-submit": _vm.headSubmit,
            },
          }),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.dataForm,
                rules: _vm.rules,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 24 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "统计日期", prop: "statDate" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", [_vm._v("统计日期:")]),
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              disabled: "true",
                              type: "date",
                              placeholder: "请选择统计日期",
                            },
                            model: {
                              value: _vm.dataForm.statDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "statDate", $$v)
                              },
                              expression: "dataForm.statDate",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "填报日期", prop: "updateTime" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("填报日期:")]),
                          ]),
                          _c("el-date-picker", {
                            attrs: {
                              format: "yyyy-MM-dd",
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              disabled: "true",
                              placeholder: "保存后自动生成",
                            },
                            model: {
                              value: _vm.dataForm.updateTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "updateTime", $$v)
                              },
                              expression: "dataForm.updateTime",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "form-divider" }),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "demo-ruleForm",
              attrs: {
                model: _vm.dataForm,
                rules: _vm.rules,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目名称", prop: "projectName" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("项目名称:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "项目名称",
                            },
                            model: {
                              value: _vm.dataForm.projectName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "projectName", $$v)
                              },
                              expression: "dataForm.projectName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "业务类型",
                            prop: "businessTypeName",
                          },
                        },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("业务类型:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "业务类型",
                            },
                            model: {
                              value: _vm.dataForm.businessTypeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "businessTypeName", $$v)
                              },
                              expression: "dataForm.businessTypeName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目状态",
                            prop: "projectStatusName",
                          },
                        },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("项目状态:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "项目状态",
                            },
                            model: {
                              value: _vm.dataForm.projectStatusName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "projectStatusName", $$v)
                              },
                              expression: "dataForm.projectStatusName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "州/省", prop: "provinceName" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("州/省:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "州/省",
                            },
                            model: {
                              value: _vm.dataForm.provinceName,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "provinceName", $$v)
                              },
                              expression: "dataForm.provinceName",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "项目经理", prop: "pmCame" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("项目经理:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              disabled: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.pmCame,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "pmCame", $$v)
                              },
                              expression: "dataForm.pmCame",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系电话", prop: "phoneNumber" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("联系电话:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              disabled: "true",
                              "controls-position": "right",
                              placeholder: "由项目数据带出",
                            },
                            model: {
                              value: _vm.dataForm.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataForm, "phoneNumber", $$v)
                              },
                              expression: "dataForm.phoneNumber",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "总人数", prop: "totalCount" } },
                        [
                          _c("template", { slot: "label" }, [
                            _c("span", [_vm._v("总人数:")]),
                          ]),
                          _c("el-input", {
                            attrs: {
                              readonly: "true",
                              "controls-position": "right",
                              placeholder: "总人数",
                            },
                            model: {
                              value: _vm.totalChineseCnt,
                              callback: function ($$v) {
                                _vm.totalChineseCnt = $$v
                              },
                              expression: "totalChineseCnt",
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("head-layout", { attrs: { "head-title": "项目人员信息" } }),
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.form1Column, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
          _c("head-layout", { attrs: { "head-title": "项目现场信息" } }),
          _c("form-layout", {
            ref: "formLayout",
            attrs: { column: _vm.form2Column, dataForm: _vm.dataForm },
            on: {
              "update:dataForm": function ($event) {
                _vm.dataForm = $event
              },
              "update:data-form": function ($event) {
                _vm.dataForm = $event
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }